import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { getUserInfo } from './utils/api';

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://66f880edce2e4bc9a8086adcb5995c09@sentry.io/1405125'
  });
}

const userInfo = getUserInfo();
if (userInfo) {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: userInfo.id.toString(),
      organization: userInfo.organization.toString()
    });
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
