import { Period, MinimalPeriod, AuditInfo, User } from './types';
import { configureScope } from '@sentry/core';

export const getToken = () => localStorage.getItem('authToken');

export const setToken = (token: string) =>
  localStorage.setItem('authToken', token);

export const deleteToken = () => localStorage.removeItem('authToken');

export const setUserInfo = (user: User) =>
  localStorage.setItem('userInfo', JSON.stringify(user));

export const getUserInfo = () => {
  const userJson = localStorage.getItem('userInfo');
  if (userJson) {
    const user: User = JSON.parse(userJson);
    return user;
  }
};
export const deleteUserInfo = () => localStorage.removeItem('userInfo');

export const getApiBase = () => {
  return import.meta.env.VITE_API_BASE || 'http://localhost:8000/api/';
};

type Headers = {
  [key: string]: string;
};

export const api = (url: string, opts: RequestInit = {}) => {
  const authHeaders: Headers = {};
  const token = getToken();
  if (token) {
    authHeaders.Authorization = `Token ${token}`;
  }

  return fetch(getApiBase() + url, {
    ...opts,
    headers: {
      ...opts.headers,
      ...authHeaders
    }
  });
};

export const isLoggedIn = () => {
  return !!getToken();
};

export const logout = () => {
  deleteToken();
};

export const login = async (
  username: string,
  password: string
): Promise<User> => {
  const res = await api('api-token-auth/', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password
    })
  });
  const json = await res.json();

  if (res.status >= 300 || !json.token) {
    throw new Error(json);
  }
  setToken(json.token);
  const userRes = await api('users/me/', {
    headers: {
      Accept: 'application/json'
    }
  });
  const user: User = await userRes.json();
  configureScope(scope => {
    scope.setUser({
      id: user.id.toString(),
      organization: user.organization.toString()
    });
  });
  setUserInfo(user);
  return user;
};

export const getPeriod = async (periodID: string) => {
  const response = await api(`periods/${periodID}/`);
  if (response.status >= 400) {
    throw new Error(await response.json());
  }
  const result: Period = await response.json();
  return result;
};

export const getPeriods = async () => {
  const response = await api(`periods/`);
  const result: MinimalPeriod[] = await response.json();
  return result;
};

export const updateAuditInfo = async (
  periodID: string | number,
  auditInfo: AuditInfo
) => {
  const response = await api(`periods/${periodID}/`, {
    method: 'patch',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    // TODO: Fix this capitalization in backend
    body: JSON.stringify({ audit_info: auditInfo })
  });
  const result: Period = await response.json();
  return result;
};

export const updatePeriod = async (
  periodID: string | number,
  period: Pick<Period, 'client' | 'periodStart' | 'periodEnd'>
) => {
  const response = await api(`periods/${periodID}/`, {
    method: 'patch',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      period_start: period.periodStart,
      period_end: period.periodEnd,
      client: period.client
    })
  });
  if (response.status >= 400) {
    throw new Error(await response.json());
  }
  return await response.json();
};

export const updatePassword = async (password: string) => {
  const response = await api(`auth/change-password`, {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      new_password: password
    })
  });
  return response;
};
