import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const RouterRoot = React.lazy(() => import('./RouterRoot'));

// Pages
const Login = React.lazy(() => import('./pages/User/Login'));

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={'/app'}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route path="/">
              <RouterRoot />
            </Route>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
